export const AGE = [
	{ id: 0, name: "0 year" },
	{ id: 1, name: "1 year" },
	{ id: 2, name: "2 years" },
	{ id: 3, name: "3 years" },
	{ id: 4, name: "4 years" },
	{ id: 5, name: "5 years" },
	{ id: 6, name: "6 years" },
	{ id: 7, name: "7 years" },
	{ id: 8, name: "8 years" },
	{ id: 9, name: "9 years" },
	{ id: 10, name: "10+ years" },
];

export const AGE_UNIT = "years";

export const CONDITIONS = [
	{ id: "used", name: "Used" },
	{ id: "new", name: "New / Unused" },
	{ id: "seller_refurbished", name: "Refurbished" },
	{ id: "defected", name: "Defected" },
	{ id: "for_parts", name: "For Parts" },
	{ id: "not_working", name: "Not Working" },
	{ id: "mix", name: "Mix" },
];

export const COND_CONDITIONS = [
	{ id: "used", name: "Used" },
	{ id: "new", name: "New / Unused" },
	{ id: "seller_refurbished", name: "Refurbished" },
	{ id: "defected", name: "Defected" },
	{ id: "for_parts", name: "For Parts" },
	{ id: "not_working", name: "Not Working" },
	{ id: "mix", name: "Mix" },
];

export const ShippingTypes = [
	{ id: "fedex", name: "FedEx" },
	{ id: "free_shipping", name: "Free Shipping" },
	{ id: "local_pickup_only", name: "Local Pickup Only" },
	{
		id: "fedex_or_local_pickup",
		name: "Both (FedEx | Local Pickup)",
	},
];

export const DEALSTATUS = [
	{ id: "Pending", name: "Pending", class: "pending" },
	{ id: "Approved", name: "Approved", class: "approved" },
	{ id: "Incomplete", name: "Incomplete", class: "incomplete" },
	{ id: "Rejected", name: "Rejected", class: "rejected" },
	{ id: "Disabled", name: "Disabled", class: "disabled" },
	{ id: "Contract", name: "Contract", class: "pending" },
	{ id: "Active", name: "Active" },
	{ id: "", name: "All", class: "disabled" },
];
export const INCOTERMS = [
	{ id: "Flexible", name: "Flexible - Open to Discuss" },
	{ id: "CIF", name: "CIF - Cost Insurance & Freight (Port)" },
	{ id: "DDP", name: "DDP - Delivered Duty Paid (Place)" },
	{ id: "EXW", name: "EXW - Ex Works (Place)" },
	{ id: "FOB", name: "FOB - Free on Board (Port)" },
	// { id: "FCA", name: "FCA - Free Carrier (Place)" },
	// { id: "FAS", name: "FAS - Free Alongside Ship (Port)" },

	// // { id: "CFR", name: "CFR - Cost and Freight (Port)" },

	// { id: "CPT", name: "CPT - Carriage Paid To (Place)" },
	// // { id: "CIP", name: "CIP - Carriage & Insurance Paid to (Place)" },
	// { id: "DPU", name: "DPU - Delivered at Place Unloaded (Place)" },
	// { id: "DAP", name: "DAP - Delivered at Place (Place)" },
];
export const COLORS = [
	{ id: "silver", name: "Silver" },
	{ id: "black", name: "Black" },
	{ id: "white", name: "White" },
	{ id: "transparent", name: "Transparent" },
];

export const STATUS = [
	{ id: "Pending", name: "Pending" },
	{ id: "Approved", name: "Approved" },
	{ id: "Incomplete", name: "Incomplete" },
	{ id: "Rejected", name: "Rejected" },
	{ id: "Disabled", name: "Disabled" },

	// {"id": "Delete", "name": "Delete"},
];

export const SUPER_STATUS = [
	{ id: "Pending", name: "Pending" },
	{ id: "Approved", name: "Approved" },
	{ id: "Incomplete", name: "Incomplete" },
	{ id: "Active", name: "Active" },
	{ id: "Disabled", name: "Disabled" },
	{ id: "Rejected", name: "Rejected" },

	// {"id": "Delete", "name": "Delete"},
];

export const ADMIN_STATUS = [
	{ id: "Approved", name: "Approved" },
	{ id: "Pending", name: "Pending" },
	{ id: "Active", name: "Active" },
	{ id: "Rejected", name: "Rejected" },
];

export const Cell_TYPE = [
	{ id: "Heterojunction", name: "Heterojunction" },
	{ id: "Mono", name: "Mono" },
	{ id: "Bifacial", name: "Bifacial" },
	{ id: "Poly", name: "Poly" },
	{ id: "TOPCon", name: "TOPCon" },
	{ id: "Q Antum", name: "Q Antum" },
	{ id: "Thin Film", name: "Thin Film" },
	{ id: "Other", name: "Other" },
];

export const CONNECTOR_TYPE = [
	{ id: "MC4 Compatible", name: "MC4 Compatible" },
	{ id: "MC4/Staubli", name: "MC4/Staubli" },
	{ id: "EVO2", name: "EVO2" },
	{ id: "QC4", name: "QC4" },
	{ id: "T4", name: "T4" },
	{ id: "T6", name: "T6" },
	{ id: "other", name: "Other" },
];

export const DAYS_OF_INSPECTION = [
	{ id: "3 days", name: "3 days" },
	{ id: "5 days", name: "5 days" },
	{ id: "10 days", name: "10 days" },
	{ id: "15 days", name: "15 days" },
	{ id: "30 days", name: "30 days" },
];

export const WIND_LOAD = [
	{ id: "2400 Pa", name: "2400 Pa" },
	{ id: "3600 Pa", name: "3600 Pa" },
	{ id: "4000 Pa", name: "4000 Pa" },
	{ id: "5400 Pa", name: "5400 Pa" },
	{ id: "6000 Pa", name: "6000 Pa" },
	{ id: "6200 Pa", name: "6200 Pa" },
];
export const SNOW_LOAD = [
	{ id: "5400 Pa", name: "5400 Pa" },
	{ id: "7000 Pa", name: "7000 Pa" },
	{ id: "8100 Pa", name: "8100 Pa" },
];
export const WEIGHT_UNIT = ["lb", "kg"];
export const LENGTH_UNIT = ["in", "cm"];

export const COMPANY_TIER = [
	{ id: "Tier 1", name: "Tier 1" },
	{ id: "Tier 2", name: "Tier 2" },
	{ id: "Tier 3", name: "Tier 3" },
];

export const INVERTER_TYPE = [
	{ id: "Single Phase", name: "Single Phase" },
	{ id: "Three Phase", name: "Three Phase" },
];

export const GRID_VOLTAGE = [
	{ id: "240 V", name: "240 V" },
	{ id: "120/208 V", name: "120/208 V" },
	{ id: "277/480 V", name: "277/480 V" },
];

export const Service_Type = [
	{ id: "Logistics", name: "Logistics" },
	{ id: "Warehousing", name: "Warehousing" },
	{ id: "Special Handling", name: "Special Handling" },
	{ id: "Sales Tax", name: "Sales Tax" },
	{ id: "Custom", name: "Custom" },
];
export const MOQUNITS = [
	{ id: "panels", name: "Panels" },
	{ id: "pallets", name: "Pallets" },
	{ id: "containers", name: "Containers" },
	{ id: "MW", name: "MW" },
];

export const brokerListingButtons = {
	moqUnit: [
		{
			param: "/mu_pallets",
			label: "Pallets",
			imgUrl: "assets/images/revamp/pallet-icon.svg",
		},
		{
			param: "/mu_containers",
			label: "Containers",
			imgUrl: "assets/images/revamp/container-icon.svg",
		},
		{
			param: "/mu_mw",
			label: "MW+",
			icon: "fas fa-bolt fa-xl",
		},
	],
	category: [
		{
			param: "/ca_panels",
			label: "Panels",
			imgUrl: "assets/images/revamp/solar-panel-icon.svg",
		},
		{
			param: "/ca_inverters",
			label: "Inverters",
			imgUrl: "assets/images/revamp/solar-inverter.svg",
		},
		{
			param: "/ca_batteries",
			label: "Batteries",
			imgUrl: "assets/images/revamp/solar-batteries.svg",
		},
	],
	wattage: [
		{
			param: "/pw_300%2c399",
			label: "300s",
		},
		{
			param: "/pw_400%2c499",
			label: "400s",
		},
		{
			param: "/pw_500%2c599",
			label: "500s",
		},
		{
			param: "/pw_600",
			label: "600+",
		},
	],
};
